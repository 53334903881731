import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import { MEDIA_QUERIES, COLORS } from "../constants"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Heading,
  MainHeading,
  Text,
} from "../components/styled/Styled"

const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: ${COLORS.GREY};
  article {
    width: 100%;
  }
`

const Article = styled.article`
  padding: 2rem;
`

const Valentina = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: ${COLORS.GREY};

  article {
    width: 50%;
    div {
      height: 100%;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    article {
      width: 100%;
      div {
        height: 400px;
      }
    }
  }
`

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    height: 400px;
  }
`

const AboutPage = () => {
  const { aboutNoi, keyMoments, valentina } = useStaticQuery(graphql`
    query {
      aboutNoi: file(relativePath: { eq: "DONNA-1.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      keyMoments: file(relativePath: { eq: "DONNA-2.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      valentina: file(relativePath: { eq: "valentina.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Us" />
      <Container>
        <MainHeading style={{ textAlign: "center" }}>ABOUT US</MainHeading>
        <motion.div
          initial={{ opacity: 0, x: -700 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.75,
              ease: "easeOut",
              type: "spring",
              damping: 50,
            },
          }}
        >
          <Section>
            <article>
              <Image fluid={aboutNoi.childImageSharp.fluid} />
            </article>
            <Article>
              <Heading>COMPANY NOI</Heading>

              <Text>
                Company NOI is an Antwerp-based dance company founded by
                Valentina Nigro in 2012. ‘Noi’, meaning ‘we’ in Italian, refers
                both to the choreographer’s Italian roots, and to the intense
                cooperation between the dancers, musicians, artists… and the
                choreographer.
              </Text>
              <Text>
                With the ambition to reach a broader audience – not only in
                Antwerp, but also in the rest of Belgium and in Europe –
                Valentina established the non-profit association Company NOI in
                the summer of 2019.
              </Text>
              <Text>
                The company consists of a group of young dancers with different
                ethnical identities, a valuable and crucial element for
                Valentina’s creations. Apart from the artistic wealth such a
                diverse group brings to the table, Company NOI is also an
                accurate representation of the intercultural society.
              </Text>
              <Text>
                Valentina believes it is important to build lasting ties with
                the artists she is working with. With Company NOI, she aims to
                create a place of growth and durability. The company wants to
                inspire people with its creations, but also to bring up social
                issues for discussion.
              </Text>
            </Article>
          </Section>
          <Section>
            <article>
              <Image fluid={keyMoments.childImageSharp.fluid} />
            </article>
            <Article>
              <Heading>KEY MOMENTS OF COMPANY NOI</Heading>

              <Text>
                2013: Company NOI debuts at the International Dance Festival in
                Prague with the choreography W.A.R., taking home the first
                price.
              </Text>
              <Text>
                2014: Company NOI performs Forego at the Mestizo Arts Festival
                in Antwerp. The piece is about the power of sacrifice, a theme
                that will return regularly.
              </Text>
              <Text>
                2016: Company NOI premieres with Donna, an ode to female energy,
                at CC Berchem in Antwerp.
              </Text>
              <Text>
                2017: Company NOI brings an adapted version of Donna during the
                expo Timeless Beauty in the Gallo-Roman Museum in Tongeren,
                Belgium.
              </Text>
              <Text>
                2019: The non-profit association is established, bringing
                Company NOI into a new era.
              </Text>
              <Text>
                2020: Company NOI performs Donna’re at the Zomer van Antwerpen.
              </Text>
            </Article>
          </Section>
          <Valentina>
            <article>
              <BackgroundImage
                style={{ width: "100%" }}
                fluid={valentina.childImageSharp.fluid}
              />
            </article>
            <article style={{ padding: "2rem" }}>
              <Heading>VALENTINA NIGRO</Heading>

              <Text>
                The Italian-Belgian Valentina (°1984) became passionate about
                dance from an early age. From 1990 until 2003 she received a
                classical dance education at the Royal Academy of Dance, London.
                Over the years, Valentina followed various ballet, jazz and
                contemporary dance courses in London, New York, Los Angeles,
                Amsterdam, and in different Belgian cities.
              </Text>
              <Text>
                From 2008, Valentina also started taking urban dance classes to
                broaden her horizons. It’s because of these influences from
                urban and classical dance that she started to develop her own
                style. In 2012, out of an urge to tell a story in her own
                movement language, Valentina founded Company NOI.
              </Text>
              <Text>
                Next to being a talented choreographer, Valentina is also a
                driven dance teacher. For over 20 years, she has been helping
                young people to reach their full potential, both as a dancer and
                a human being.
              </Text>
            </article>
          </Valentina>
        </motion.div>
      </Container>
    </Layout>
  )
}

export default AboutPage
